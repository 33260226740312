import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import faqSectionStyle from "assets/jss/xrs/views/sectionsSections/faqSectionStyle";
import Context from "../../../utils/context";
import * as URLS from "../../../utils/XRSAPIs";
import * as ACTIONS from "../../../store/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { ApiErrorHandler } from "utils/apiErrorHandler";
import { primaryColor } from "assets/jss/xrs.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import BlueHeader from "./BlueHeaderSection.js";
import ReactHtmlParser from "react-html-parser";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import Accordion from "components/Accordion/Accordion.js";

const columns = [
  { id: "Description", label: "Description", minWidth: 100 },
  { id: "Account #", label: "Account #", minWidth: 170 },
  {
    id: "Balance",
    label: "Balance",
    minWidth: 100,
    align: "right",
    format: (value) => value.toFixed(2),
  },
];

const useStyles = makeStyles(faqSectionStyle);
export default function FaqSection(props) {
  const context = useContext(Context);
  const classes = useStyles();
  const createData = (title, content) => {
    return { title, content };
  };
  const dispatch = useDispatch();
  const selectedAccount = useSelector(
    (state) => state.SelectedAccountsReducer.account
  );
  const stateAccounts = useSelector((state) => state.AccountsReducer.accounts);

  const [rowdata, setRowdata] = React.useState([]);
  const [site, setSite] = React.useState("");
  const stateSite = useSelector((state) => state.SiteReducer.site);
  const stateCustomer = useSelector((state) => state.CustomerReducer.customer);
  const ReadContext = () => {
    setSite(stateSite);
    const dataRows = [];
    var data = stateSite.faqs;
    if (data != "NoAccounts") {
      var i = 1;
      data.forEach((item) => {
        dataRows.push(
          createData(
            ReactHtmlParser(item.header),
            ReactHtmlParser(item.details)
          )
        );
        i++;
        setRowdata(dataRows);
      });
    }
  };
  React.useEffect(() => {
    fetch(URLS.GetSiteDetatilsAPIUrl, {
      method: "GET",
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Get Site Detatils", data, context);
          });
          throw Error("");
        }
      })
      .then((response) => {
        dispatch(ACTIONS.set_Site(response));

        ReadContext();
      })
      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Get Site Detatils", error, context);
        }
      });
  }, []);
  return (
    <div>
      <BlueHeader></BlueHeader>
      <Card style={{ backgroundColor: primaryColor[19], margin: 0 }}>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <div>
                <h3>Frequently Asked Questions</h3>
                <Accordion active={0} collapses={rowdata} />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <h5 className={classes.blueTitle}>Account Number</h5>
              <p className={classes.RightText}>
                {selectedAccount !== "NoAccount"
                  ? selectedAccount.accountnumber
                  : stateCustomer.custnumber}
              </p>
              <h5 className={classes.blueTitle}>Opening Hours</h5>
              <p className={classes.RightText}>
                {selectedAccount !== "NoAccount"
                  ? selectedAccount.deptofficehours
                  : stateAccounts[0].deptofficehours}
              </p>
              <h5 className={classes.blueTitle}>Address</h5>
              <p className={classes.RightText}>
                {selectedAccount !== "NoAccount"
                  ? selectedAccount.deptaddress
                  : stateAccounts[0].deptaddress}
              </p>
              <h5 className={classes.blueTitle}>Phone</h5>
              <p className={classes.RightText}>
                {" "}
                {selectedAccount !== "NoAccount"
                  ? selectedAccount.deptphonenumber
                  : stateAccounts[0].deptphonenumber}
              </p>
              <h5 className={classes.blueTitle}>Email</h5>
              <p className={classes.RightText}>
                {selectedAccount !== "NoAccount"
                  ? selectedAccount.deptemail
                  : stateAccounts[0].deptemail}
              </p>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </div>
  );
}
