import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import welcomeSectionStyle from "assets/jss/xrs/views/sectionsSections/welcomeSectionStyle";
import Context from "../../../utils/context";
import * as URLS from "../../../utils/XRSAPIs";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import BlueHeader from "./BlueHeaderSection.js";
import ReactHtmlParser from "react-html-parser";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import { isClassExpression } from "typescript";
import { ApiErrorHandler } from "utils/apiErrorHandler";
import * as ACTIONS from "../../../store/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { primaryColor } from "assets/jss/xrs.js";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

const columns = [
  { id: "Description", label: "Description", minWidth: 50 },
  { id: "Account #", label: "Account #", minWidth: 50 },
  {
    id: "Balance",
    label: "Balance",
    minWidth: 50,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Arrears",
    label: "Arrears",
    minWidth: 50,
    align: "right",
    format: (value) => value.toFixed(2),
  },
];

const MobileColumns = [
  { id: "Account #", label: "Account #", minWidth: 50 },
  {
    id: "Balance",
    label: "Balance",
    minWidth: 50,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Arrears",
    label: "Arrears",
    minWidth: 50,
    align: "right",
    format: (value) => value.toFixed(2),
  },
];

const useStyles = makeStyles(welcomeSectionStyle);
export default function WellcomeSection(props) {
  const context = useContext(Context);
  const classes = useStyles();
  const createData = (orgname, accountnumber, balance, arrearsamount, Id) => {
    return { Id, orgname, accountnumber, balance, arrearsamount };
  };
  const dispatch = useDispatch();
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  const [render, setRender] = React.useState(0);
  const [rowdata, setRowdata] = React.useState([]);
  const [site, setSite] = React.useState("");
  const stateAccounts = useSelector((state) => state.AccountsReducer.accounts);
  const stateCustomer = useSelector((state) => state.CustomerReducer.customer);
  const currency = useSelector((state) => state.GetSiteDetailsReducer.currency);
  const selectedAccount = useSelector(
    (state) => state.SelectedAccountsReducer.account
  );

  const ReadContext = (accounts) => {
    const dataRows = [];
    var data = accounts;
    if (data != "NoAccounts") {
      var i = 1;
      data.forEach((item) => {
        dataRows.push(
          createData(
            item.orgname,
            item.accountnumber,
            item.balance,
            item.arrearsamount,
            i
          )
        );
        i++;
      });
      setRowdata(dataRows);
    }
  };
  var token = sessionStorage.getItem("access_token");

  React.useEffect(() => {
    fetch(URLS.GetSiteDetatilsAPIUrl, {
      method: "GET",
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Get Site Detatils", data, context);
          });
          throw Error("");
        }
      })
      .then((response) => {
        dispatch(ACTIONS.set_Site(response));

        setSite(response);
      })
      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Get SiteDetatils", error, context);
        }
      });
  }, []);

  React.useEffect(() => {
    if (!context.authObj.isAuthenticatedUser()) {
      context.authObj.logout();
    } else {
      setBallance(stateCustomer.totalbalance);
      ReadContext(stateAccounts);
    }
  }, [ballance]);
  const [ballance, setBallance] = React.useState(0);
  return (
    <div>
      <BlueHeader></BlueHeader>
      <Card style={{ backgroundColor: primaryColor[19], margin: 0 }}>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              {ReactHtmlParser(stateCustomer.welcomE1)}
              <BrowserView>
                <div className={classes.tableWrapper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              fontSize: 16,
                              fontWeight: 600,
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowdata.map((row) => (
                        <TableRow key={row.Id}>
                          <TableCell align="left">{row.orgname}</TableCell>
                          <TableCell align="left">
                            {row.accountnumber}
                          </TableCell>
                          <TableCell align="right">
                            <NumberFormat
                              value={row.balance}
                              displayType={"text"}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              decimalScale={2}
                              prefix={currency}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <NumberFormat
                              value={row.arrearsamount}
                              displayType={"text"}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              decimalScale={2}
                              prefix={currency}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </BrowserView>
              <MobileView>
                <GridContainer className={classes.tableWrapperMobile}>
                  {rowdata.map(function(row, index) {
                    return (
                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem
                          className={classes.groupRow}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          {row.orgname} ({row.accountnumber})
                        </GridItem>
                        <GridItem
                          className={classes.accountRow}
                          xs={12}
                          sm={12}
                          md={12}
                          align="left"
                        >
                          <NumberFormat
                            value={row.balance}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </GridItem>
                        <GridItem
                          className={classes.groupRow}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          Arrears
                        </GridItem>
                        <GridItem
                          className={classes.accountRow}
                          xs={12}
                          sm={12}
                          md={12}
                          align="left"
                        >
                          <NumberFormat
                            value={row.arrearsamount}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </GridItem>
                      </GridContainer>
                    );
                  })}
                </GridContainer>
              </MobileView>

              <span>{ReactHtmlParser(stateCustomer.welcomE2)}</span>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <h5 className={classes.blueTitle}>Account Number</h5>
              <p className={classes.RightText}>
                {selectedAccount !== "NoAccount"
                  ? selectedAccount.accountnumber
                  : stateCustomer.custnumber}
              </p>
              <h5 className={classes.blueTitle}>Opening Hours</h5>
              <p className={classes.RightText}>
                {selectedAccount !== "NoAccount"
                  ? selectedAccount.deptofficehours
                  : stateAccounts[0].deptofficehours}
              </p>
              <h5 className={classes.blueTitle}>Address</h5>
              <p className={classes.RightText}>
                {selectedAccount !== "NoAccount"
                  ? selectedAccount.deptaddress
                  : stateAccounts[0].deptaddress}
              </p>
              <h5 className={classes.blueTitle}>Phone</h5>
              <p className={classes.RightText}>
                {" "}
                {selectedAccount !== "NoAccount"
                  ? selectedAccount.deptphonenumber
                  : stateAccounts[0].deptphonenumber}
              </p>
              <h5 className={classes.blueTitle}>Email</h5>
              <p className={classes.RightText}>
                {selectedAccount !== "NoAccount"
                  ? selectedAccount.deptemail
                  : stateAccounts[0].deptemail}
              </p>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </div>
  );
}
